/*================================================================================== */
/* Jquery START ==================================================================== */
jQuery(document).ready(function ($) {
    "use strict";

    $("#open-form").click(
        function() {
            $("#candidature").slideToggle();
        }
    );

    $('#naissance').datepicker({
        dateFormat: 'dd-mm-yy',
        changeMonth: true,
        changeYear: true,
        yearRange: "-100:+0", // last hundred years
        maxDate: "-16Y"
    });

});
